import React, { useEffect, useState } from "react";

import {
    Button,
    CheckIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LoadingButton,
    Typography,
} from "@firecms/ui";
import { CircularProgressCenter, useSnackbarController } from "@firecms/core";
import { Formex, useCreateFormex } from "@firecms/formex";
import {
    ProductPrice,
    ProductWithPrices,
    ProLicense,
    SubscriptionPriceSelect,
    useFireCMSBackend,
    useLicensesForUserController,
    useSubscriptionsForUserController
} from "@firecms/cloud";
import { TierPricingTable } from "./TierPricingTable";
import { createPerProjectLicenseSubscription } from "./common";
import { generateApiKey } from "./api_keys";
import { ProjectsEditView } from "./ProjectsEditView";

export function LicensePurchaseDetailsForm({
                                               open,
                                               license,
                                               handleClose
                                           }: {
    open: boolean,
    license?: ProLicense,
    handleClose: () => void
}) {

    const {
        products,
    } = useSubscriptionsForUserController();

    const product: ProductWithPrices | undefined = products?.find(p => p.metadata?.type === "pro");

    const perUserProductPrices: ProductPrice[] | undefined = product?.prices.filter(p => !p.metadata.type || p.metadata.type === "per_user");
    const perProjectProductPrices: ProductPrice[] | undefined = product?.prices.filter(p => p.metadata.type === "per_project");

    return (
        <PerProjectView open={open}
                        perProjectProductPrices={perProjectProductPrices}
                        product={product}
                        handleClose={handleClose}
                        license={license}
        />
    );

    // return (
    //     <PerUserView open={open}
    //                  perUserProductPrices={perUserProductPrices}
    //                  product={product}
    //                  license={license}
    //                  handleClose={handleClose}
    //                  isNewProLicense={isNewProLicense}
    //
    //     />
    // );
}

function PerProjectView({
                            open,
                            license,
                            handleClose,
                            product,
                            perProjectProductPrices
                        }: {
    open: boolean,
    license?: ProLicense,
    handleClose: () => void,
    product: ProductWithPrices | undefined,
    perProjectProductPrices: ProductPrice[] | undefined,
}) {

    const {
        productsLoading,
        subscribe
    } = useSubscriptionsForUserController();

    const snackbarController = useSnackbarController();

    const [selectedPrice, setSelectedPrice] = useState<ProductPrice>();
    useEffect(() => {
        if (perProjectProductPrices && perProjectProductPrices.length > 0) {
            setSelectedPrice(perProjectProductPrices[0]);
        }
    }, [perProjectProductPrices]);

    if (product && product?.metadata.type !== "pro" && product?.metadata.type !== "cloud_plus") {
        throw new Error("Error: Unmapped product type in ProductView");
    }

    const largePriceLabel = false;

    const [linkLoading, setLinkLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    const { user } = useFireCMSBackend();
    const {
        createLicense,
    } = useLicensesForUserController();

    const formex = useCreateFormex({
        initialValues: license ?? {
            archived: false,
            type: "per_project",
            api_key: generateApiKey(),
            firebase_project_ids: [],
            created_at: new Date(),
            created_by: user?.uid ?? "",
        } as Partial<ProLicense>,
        onSubmit: (license: Partial<ProLicense>, formexController) => {

            async function navigateToStripe(updatedLicense: ProLicense) {

                if (!selectedPrice) {
                    throw new Error("No price selected");
                }
                setLinkLoading(true);
                await createPerProjectLicenseSubscription(subscribe, updatedLicense, selectedPrice)
                    .catch((e) => setError(e))
                    .finally(() => setLinkLoading(false));
            }

            return createLicense(license)
                .then(async (updatedLicense) => {
                    await navigateToStripe(updatedLicense);
                    handleClose();
                    formexController.resetForm({
                        values: license
                    });
                }).catch((e) => {
                    console.error(e);
                    snackbarController.open({
                        type: "error",
                        message: e.message
                    });
                });
        }
    });

    const {
        isSubmitting,
        touched,
        handleChange,
        values,
        errors,
        setFieldValue,
        dirty,
        handleSubmit,
        submitCount
    } = formex;

    const hasError = Boolean(errors.licensed_users);

    if (productsLoading) {
        return <CircularProgressCenter/>
    }

    const projectsCount = (values.firebase_project_ids ?? []).filter(Boolean).length;

    return <Dialog
        open={open}
        onOpenChange={(open) => !open ? handleClose() : undefined}
        maxWidth={"2xl"}
    >
        <Formex value={formex}>
            <form
                onSubmit={handleSubmit}
                autoComplete={"off"}
                noValidate
                style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    height: "100%"
                }}>

                <DialogTitle variant={"h4"} gutterBottom={false}>
                    Purchase new license
                </DialogTitle>
                <DialogContent className="h-full flex-grow">

                    <div className={"flex flex-col gap-4"}>

                        <Typography>
                            You can use a license for multiple projects.
                            You can have unlimited users in each project.
                        </Typography>

                        <Typography>
                            You are free to <b>resell</b> your license to your customers.
                        </Typography>

                        <div>

                            <Typography variant={"caption"} color={"secondary"} gutterBottom={true}>
                                {submitCount > 0 && Boolean(errors.licensed_users) ? errors.licensed_users : "Please enter the Firebase project IDs you want to link to this license."}
                            </Typography>
                            <ProjectsEditView projectIds={values.firebase_project_ids ?? [""]}
                                              setProjectIds={(projectIds) => setFieldValue("firebase_project_ids", projectIds)}/>

                        </div>

                        <div className={"flex flex-row gap-2 items-center"}>
                            {/*<Typography variant={"label"}>Price:</Typography>*/}
                            <SubscriptionPriceSelect productPrices={perProjectProductPrices}
                                                     fullWidth={false}
                                                     setSelectedPrice={setSelectedPrice}
                                                     largePriceLabel={largePriceLabel}
                                                     selectedPrice={selectedPrice}/></div>

                        {selectedPrice && <TierPricingTable price={selectedPrice}/>}

                    </div>

                </DialogContent>

                <DialogActions>

                    <Button variant={"text"}
                            onClick={() => {
                                handleClose();
                            }}>
                        Cancel
                    </Button>

                    <LoadingButton
                        variant="filled"
                        color="primary"
                        type="submit"
                        disabled={hasError || projectsCount === 0}
                        loading={isSubmitting || linkLoading}
                        startIcon={<CheckIcon/>}
                    >
                        Create license
                    </LoadingButton>

                </DialogActions>
            </form>
        </Formex>

    </Dialog>;
}

// /**
//  * @deprecated
//  */
// function PerUserView({
//                          open,
//                          handleClose,
//                          license,
//                          isNewProLicense,
//                          product,
//                          perUserProductPrices
//                      }: {
//     open: boolean,
//     license?: ProLicense,
//     handleClose: () => void,
//     isNewProLicense: boolean,
//     product: ProductWithPrices | undefined,
//     perUserProductPrices: ProductPrice[] | undefined,
// }) {
//
//     const {
//         products,
//         productsLoading,
//         subscribe
//     } = useSubscriptionsForUserController();
//
//     const snackbarController = useSnackbarController();
//
//     const [selectedPrice, setSelectedPrice] = useState<ProductPrice>();
//     useEffect(() => {
//         if (perUserProductPrices && perUserProductPrices.length > 0) {
//             setSelectedPrice(perUserProductPrices[0]);
//         }
//     }, [perUserProductPrices]);
//
//     if (product && product?.metadata.type !== "pro" && product?.metadata.type !== "cloud_plus") {
//         throw new Error("Error: Unmapped product type in ProductView");
//     }
//
//     const largePriceLabel = false;
//
//     const [linkLoading, setLinkLoading] = useState<boolean>(false);
//     const [error, setError] = useState<Error>();
//
//     const { user } = useFireCMSBackend();
//     const {
//         createLicense,
//     } = useLicensesForUserController();
//
//     const formex = useCreateFormex({
//         initialValues: license ?? {
//             licensed_users: 5,
//             archived: false,
//             api_key: generateApiKey(),
//             firebase_project_ids: [],
//             created_at: new Date(),
//             created_by: user?.uid ?? "",
//         } as Partial<ProLicense>,
//         // validation: (values) => {
//         //     return ProLicenseYupSchema.validate(values, { abortEarly: false })
//         //         .then(() => {
//         //             return {};
//         //         }).catch((e) => {
//         //             return e.inner.reduce((acc: any, error: any) => {
//         //                 acc[error.path] = error.message;
//         //                 return acc;
//         //             }, {});
//         //         });
//         // },
//         onSubmit: (license: Partial<ProLicense>, formexController) => {
//
//             async function navigateToStripe(updatedLicense: ProLicense) {
//
//                 if (!selectedPrice) {
//                     throw new Error("No price selected");
//                 }
//                 setLinkLoading(true);
//                 await createLicenseSubscription(subscribe, updatedLicense, selectedPrice)
//                     .catch((e) => setError(e))
//                     .finally(() => setLinkLoading(false));
//             }
//
//             if (!isNewProLicense) {
//                 navigateToStripe(license as ProLicense).then(() => {
//                     handleClose();
//                 });
//                 return Promise.resolve();
//             }
//
//             return createLicense(license)
//                 .then(async (updatedLicense) => {
//
//                     if (isNewProLicense) {
//                         await navigateToStripe(updatedLicense);
//                     }
//                     handleClose();
//                     formexController.resetForm({
//                         values: license
//                     });
//                 }).catch((e) => {
//                     console.error(e);
//                     snackbarController.open({
//                         type: "error",
//                         message: e.message
//                     });
//                 });
//         }
//     });
//
//     const {
//         isSubmitting,
//         touched,
//         handleChange,
//         values,
//         errors,
//         setFieldValue,
//         dirty,
//         handleSubmit,
//         submitCount
//     } = formex;
//
//     const hasError = Boolean(errors.licensed_users);
//
//     if (productsLoading) {
//         return <CircularProgressCenter/>
//     }
//
//     return <Dialog
//         open={open}
//         onOpenChange={(open) => !open ? handleClose() : undefined}
//         maxWidth={"4xl"}
//     >
//         <Formex value={formex}>
//             <form
//                 onSubmit={handleSubmit}
//                 autoComplete={"off"}
//                 noValidate
//                 style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     position: "relative",
//                     height: "100%"
//                 }}>
//
//                 <DialogTitle variant={"h4"} gutterBottom={false}>
//                     Buy new subscription
//                 </DialogTitle>
//                 <DialogContent className="h-full flex-grow">
//
//                     <div className={"flex flex-col gap-8"}>
//
//                         <Typography>
//                             You need a license to use FireCMS PRO. You can use a license for multiple projects.
//                             You get charged per project. You can have unlimited users in each project.
//                         </Typography>
//
//                         <Typography>
//                             You are free to <b>resell</b> your license to your customers.
//                         </Typography>
//
//                         <div>
//                             <TextField
//                                 name="licensed_users"
//                                 required
//                                 error={submitCount > 0 && Boolean(errors.licensed_users)}
//                                 value={values.licensed_users}
//                                 onChange={handleChange}
//                                 type={"number"}
//                                 label="Licensed users"
//                             />
//                             <FieldCaption>
//                                 {submitCount > 0 && Boolean(errors.licensed_users) ? errors.licensed_users : "How many users will be able to use this license?"}
//                             </FieldCaption>
//                         </div>
//
//                         {isNewProLicense && <div className={"flex flex-row gap-2 items-center px-2"}>
//                             <Typography variant={"label"}>Currency:</Typography>
//                             <SubscriptionPriceSelect productPrices={perUserProductPrices}
//                                                      fullWidth={false}
//                                                      setSelectedPrice={setSelectedPrice}
//                                                      largePriceLabel={largePriceLabel}
//                                                      selectedPrice={selectedPrice}/></div>}
//
//                         {selectedPrice && <TierPricingTable price={selectedPrice}/>}
//
//                     </div>
//
//                 </DialogContent>
//
//                 <DialogActions>
//
//                     <Button variant={"text"}
//                             onClick={() => {
//                                 handleClose();
//                             }}>
//                         Cancel
//                     </Button>
//
//                     <LoadingButton
//                         variant="filled"
//                         color="primary"
//                         type="submit"
//                         disabled={hasError}
//                         loading={isSubmitting || linkLoading}
//                         startIcon={<CheckIcon/>}
//                     >
//                         {isNewProLicense ? "Create license" : "Add subscription"}
//                     </LoadingButton>
//
//                 </DialogActions>
//             </form>
//         </Formex>
//
//     </Dialog>;
// }
