import {
    Chip,
    ConfirmationDialog,
    getLicenseStatus,
    ProLicense,
    Subscription,
    Table,
    TableBody,
    TableRow,
    useLicensesForUserController,
} from "@firecms/cloud";

import { AddIcon, Button, Paper, TableCell, Typography, } from "@firecms/ui";
import React, { useCallback, useEffect, useState } from "react";
import { LicensePurchaseDetailsForm } from "./LicensePurchaseDetailsForm";
import { ProLicenseView } from "./ProLicenseView";

export function ProLicenses() {

    const [dialogOpen, setDialogOpen] = useState<boolean>();
    const [selectedProLicense, setSelectedProLicense] = useState<ProLicense | undefined>();

    const onMissingSubscriptionClick = useCallback((license: ProLicense) => {
        setSelectedProLicense(license);
        setDialogOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setDialogOpen(false);
        setSelectedProLicense(undefined);
    }, []);

    const { licenses } = useLicensesForUserController();

    return <>
        <div className={"mt-16 mb-8"}>
            <div
                className="flex items-center mt-12 ">

                <Typography variant={"h4"} className={"flex-grow"}>Your PRO licenses</Typography>

                <Button
                    startIcon={<AddIcon/>}
                    onClick={() => setDialogOpen(true)}>
                    Create new license
                </Button>
            </div>
        </div>

        <Typography variant={"body1"} className={"my-4"}>
            Create subscriptions in this section only for <b>self hosted</b> FireCMS PRO. If you are using FireCMS
            Cloud, you can upgrade your project from within the project settings.
        </Typography>

        <Typography variant={"caption"}>
            If you are an agency, you are free to <b>resell</b> your license to your customers.
        </Typography>

        <Typography variant={"caption"} className={"my-4"}>
            If you have any questions or need help, please contact us at <a href="mailto:hello@firecms.co">
            hello@firecms.co</a>
        </Typography>

        {licenses && licenses.length > 0 && <Table className="table-auto">
            <TableBody>
                {licenses && licenses.length > 0 &&
                    licenses.map((license) => (
                        <ProLicenseRow key={license.id}
                                       license={license}
                                       onMissingSubscriptionClick={onMissingSubscriptionClick}
                        />
                    ))}
            </TableBody>
        </Table>}

        {(!licenses || licenses.length === 0) &&
            <Paper className={"p-4"}>
                <Typography variant={"label"}>You have not created any FireCMS PRO licenses yet</Typography>
            </Paper>}

        {/*<div className={"flex flex-col gap-4 mt-8"}>*/}
        {/*    {licenses && licenses.length > 0 &&*/}
        {/*        licenses.map((license) => (*/}
        {/*            <ProLicenseView key={license.id}*/}
        {/*                            license={license}*/}
        {/*                            onMissingSubscriptionClick={onMissingSubscriptionClick}*/}
        {/*            />*/}
        {/*        ))}*/}
        {/*    {(!licenses || licenses.length === 0) &&*/}
        {/*        <Paper className={"p-4"}>*/}
        {/*            <Typography variant={"label"}>You have not created any FireCMS PRO licenses yet</Typography>*/}
        {/*        </Paper>}*/}

        {/*</div>*/}

        <LicensePurchaseDetailsForm
            key={selectedProLicense?.id ?? "new"}
            open={dialogOpen ?? false}
            license={selectedProLicense}
            handleClose={handleClose}/>

    </>;
}

function ProLicenseRow({
                           license,
                           onMissingSubscriptionClick
                       }: {
    license: ProLicense,
    onMissingSubscriptionClick: (license: ProLicense) => void
}) {

    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    const {
        listenSubscriptionsForLicense,
        updateLicense
    } = useLicensesForUserController();

    useEffect(() => {
        return listenSubscriptionsForLicense(
            license.id,
            setSubscriptions,
            (error) => console.error(error)
        );
    }, [license.id, listenSubscriptionsForLicense]);

    const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false);

    const hasActiveSubscriptions = subscriptions.some(subscription => subscription.status === "active");
    const statusText = getLicenseStatus(subscriptions);

    return <>
        <TableRow onClick={() => setOpen(true)}>
            <TableCell
                className={"w-full"}
                align="left">
                <Typography variant={"subtitle2"} className={"flex-grow"}>
                    License {license.id}
                </Typography>
                <Typography>
                    {license.firebase_project_ids.join(", ")}
                </Typography>
            </TableCell>
            <TableCell>
                {!hasActiveSubscriptions && !license.archived && <Button variant={"text"}
                                                                         size={"small"}
                                                                         color={"text"}
                                                                         onClick={() => {
                                                                             setArchiveDialogOpen(true);
                                                                         }}>
                    Archive
                </Button>}
            </TableCell>
            <TableCell
                align={"right"}>
                <Chip
                    size={"small"}
                    colorScheme={statusText === "Active" ? "greenDark" : "orangeDark"}>
                    {statusText}
                </Chip>
            </TableCell>
            <TableCell
                className={"w-max"}
                align={"right"}>
                {license.licensed_projects ?? 0} licensed projects
            </TableCell>
        </TableRow>

        <ProLicenseView
            open={open}
            setOpen={setOpen}
            license={license}
            onMissingSubscriptionClick={onMissingSubscriptionClick}/>

        <ConfirmationDialog open={archiveDialogOpen}
                            onAccept={() => {
                                updateLicense(license.id, { archived: true });
                                setArchiveDialogOpen(false);
                            }}
                            onCancel={() => setArchiveDialogOpen(false)}
                            title={"Archive License?"}
                            body={
                                <Typography variant={"body2"}>
                                    This action will archive the license and remove it from the active licenses
                                    list.
                                    You might continue using the related projects while a valid subscription is
                                    active.
                                </Typography>}
        />
    </>;
}

