import {
    Button,
    CheckCircleOutlineIcon,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    getSubscriptionStatusText,
    HighlightOffIcon,
    ProLicense,
    Subscription,
    Typography,
    useFireCMSBackend,
    useLicensesForUserController,
} from "@firecms/cloud";
import { useEffect, useState } from "react";
import { generateApiKey } from "./api_keys";
import { ProjectsEditView } from "./ProjectsEditView";

export function ProLicenseView({
                                   open,
                                   setOpen,
                                   license,
                                   onMissingSubscriptionClick
                               }: {
    open: boolean;
    setOpen: (open: boolean) => void;
    license: ProLicense,
    onMissingSubscriptionClick: (license: ProLicense) => void
}) {

    console.log("ProLicenseView", license);

    const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
    const {
        listenSubscriptionsForLicense,
        updateLicense
    } = useLicensesForUserController();

    useEffect(() => {
        return listenSubscriptionsForLicense(
            license.id,
            setSubscriptions,
            (error) => console.error(error)
        );
    }, [license.id, listenSubscriptionsForLicense]);

    const [licenseValue, setLicenseValue] = useState<ProLicense>(license);

    const licenseType = license.type ?? "per_user";

    const totalLicensedProjects = licenseType === "per_project" ? subscriptions
            .filter(subscription => subscription.status === "active")
            .reduce((acc, subscription) => acc + (subscription.quantity ?? 0), 0)
        : undefined;

    return (
        <Dialog open={open} maxWidth={"3xl"}>
            <DialogTitle variant={"h5"} gutterBottom={false}>
                License details
            </DialogTitle>
            <DialogContent >
                <div className="flex flex-col sm:flex-row sm:justify-between mt-2">

                    <div className="mb-4">
                        <Typography variant="body2" color="secondary">{license.id}</Typography>

                        <div className="flex flex-row gap-4">
                            <Typography variant="body2" color={"secondary"}>Created At</Typography>
                            <Typography variant="body2">{license.created_at?.toLocaleDateString()}</Typography>
                        </div>

                    </div>
                </div>

                {subscriptions.length === 0 && (
                    <>
                        <Typography variant="body2" color={"disabled"} className={"mb-2"}>
                            No active subscriptions
                        </Typography>
                        <Button variant={"outlined"}
                                onClick={() => {
                                    onMissingSubscriptionClick(license);
                                }}>
                            Subscribe
                        </Button>

                    </>
                )}

                {/* API key generation */}
                <div className="flex flex-col gap-2 mt-4">
                    <Typography variant="subtitle1">API Key</Typography>
                    <Typography variant="body2">
                        You need to pass this key to your <code>FireCMS</code> component to enable the PRO features.
                    </Typography>
                    <div className={"flex flex-row gap-2"}>
                        <code className="flex-grow p-2 mb-4 bg-surface-50 dark:bg-surface-900 rounded">
                            {licenseValue.api_key}
                        </code>
                        <Button variant={"text"}
                                onClick={() => {
                                    setLicenseValue({
                                        ...licenseValue,
                                        api_key: generateApiKey()
                                    })
                                }}>
                            Regenerate
                        </Button>
                    </div>
                </div>

                {subscriptions.length > 0 && (
                    <>
                        {subscriptions.map((subscription) => (
                            <LicenseSubscriptionView
                                key={subscription.id}
                                licenseType={licenseType}
                                subscription={subscription}
                            />
                        ))}
                    </>
                )}

                <div className="flex flex-col gap-2 mt-8">
                    <Typography variant="subtitle1" className={"flex-1"}>Related Firebase Projects</Typography>

                    <Typography variant="body2">
                        These are the Firebase projects associated with this license. You can use your license
                        in any of the projects defined here.
                    </Typography>
                    <Typography variant="body2">
                        You need an active subscription with the required number of licensed projects to update this
                        list.
                    </Typography>
                    <ProjectsEditView projectIds={licenseValue?.firebase_project_ids ?? []}
                                      setProjectIds={(projectIds) => {
                                          setLicenseValue({
                                              ...licenseValue,
                                              firebase_project_ids: projectIds ?? []
                                          });
                                      }}
                                      maxProjects={totalLicensedProjects}/>
                </div>

            </DialogContent>
            <DialogActions>
                <Button variant={"text"} onClick={() => {
                    setLicenseValue(license);
                    setOpen(false);
                }}>
                    Close
                </Button>
                <Button onClick={() => {
                    updateLicense(license.id, licenseValue);
                    setLicenseValue(license);
                    setOpen(false);
                }}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function LicenseSubscriptionView({
                                     subscription,
                                     licenseType
                                 }: {
    subscription: Subscription;
    licenseType: "per_project" | "per_user";
}) {
    const { projectsApi } = useFireCMSBackend();
    const statusText = getSubscriptionStatusText(subscription);

    const [stripeCancelPortalUrl, setStripeCancelPortalUrl] = useState<string>();
    const [stripeUpdatePortalUrl, setStripeUpdatePortalUrl] = useState<string>();
    const [stripeCancelPortalUrlLoading, setStripeCancelPortalUrlLoading] = useState<boolean>(false);
    const [stripeUpdatePortalUrlLoading, setStripeUpdatePortalUrlLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!subscription.canceled_at) {
            setStripeCancelPortalUrlLoading(true);
            projectsApi
                .getStripeCancelLinkForSubscription(subscription.id)
                .then(setStripeCancelPortalUrl)
                .catch(console.error)
                .finally(() => setStripeCancelPortalUrlLoading(false));
        }
    }, [subscription.id, subscription.canceled_at, projectsApi]);

    useEffect(() => {
        setStripeUpdatePortalUrlLoading(true);
        projectsApi
            .getStripeUpdateLinkForSubscription(subscription.id)
            .then(setStripeUpdatePortalUrl)
            .catch(console.error)
            .finally(() => setStripeUpdatePortalUrlLoading(false));
    }, [subscription.id, projectsApi]);

    const isActive = subscription.status === "active";

    const StatusIcon = isActive ? CheckCircleOutlineIcon : HighlightOffIcon;

    return (
        <div className={"mt-4"}>
            <Typography variant="subtitle1" className={"flex flex-row gap-4"}>
                Subscription
                <Chip
                    size={"small"}
                    colorScheme={statusText === "Active" ? "greenDark" : "orangeDark"}>
                    {statusText}
                </Chip>


            </Typography>
            <Typography variant="body2" color="secondary">
                {subscription.id}
            </Typography>

            <div className="flex flex-col items-start">
                {/*<StatusIcon*/}
                {/*    className={isActive ? "text-green-500" : "text-red-500"}*/}
                {/*    style={{ fontSize: 32 }}*/}
                {/*/>*/}
                <div className={"flex flex-row gap-4 w-full"}>

                    <div className={"flex-1 flex flex-row gap-2"}>
                        {licenseType == "per_project" &&
                            <Typography variant="body1" color={"secondary"}>Licensed projects</Typography>}
                        {licenseType == "per_user" && <Typography variant="body1">Licensed Users</Typography>}
                        {licenseType == "per_project" &&
                            <Typography variant="subtitle2">{subscription.quantity ?? "-"}</Typography>}
                        {licenseType == "per_user" &&
                            <Typography variant="subtitle2">{subscription.quantity ?? "-"}</Typography>}
                    </div>
                    <div className={"flex flex-row gap-4 items-center"}>
                        {isActive ? (
                            <>
                                {!subscription.canceled_at && <a
                                    className={"text-sm color-inherit"}
                                    href={stripeCancelPortalUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    color="text"
                                >
                                    {stripeCancelPortalUrlLoading ? <CircularProgress size={"smallest"}/> : "Cancel"}
                                </a>}
                                <Button
                                    component="a"
                                    variant={"outlined"}
                                    size="small"
                                    href={stripeUpdatePortalUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {stripeUpdatePortalUrlLoading
                                        ? <CircularProgress size={"smallest"}/>
                                        : (subscription.canceled_at ? "Renew" : licenseType === "per_user" ? "Update licensed users count" : "Update licensed projects count")}
                                </Button>
                            </>
                        ) : (
                            <Button
                                component="a"
                                size="small"
                                href={stripeCancelPortalUrl}
                                target="_blank"
                                rel="noreferrer"
                                color="primary"
                            >
                                {stripeCancelPortalUrlLoading ? <CircularProgress size={"smallest"}/> : "Renew"}
                            </Button>
                        )}
                    </div>
                </div>


                {/*{subscription.cancel_at && (*/}
                {/*    <Typography variant="body2" className="text-red-500 dark:text-red-500">*/}
                {/*        Active until{" "}*/}
                {/*        {subscription.cancel_at.toDate().toLocaleDateString()}*/}
                {/*    </Typography>*/}
                {/*)}*/}
            </div>

        </div>
    );
}
