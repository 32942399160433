import { ProductPrice, ProLicense, SubscribeParams } from "@firecms/cloud";

export async function createPerProjectLicenseSubscription(subscribe: (params: SubscribeParams) => Promise<void>, license: ProLicense, price: ProductPrice) {

    const projectsCount = license.firebase_project_ids.filter(Boolean).length;
    if (projectsCount === 0) {
        throw new Error("INTERNAL: No projects to subscribe to");
    }
    return subscribe({
        licenseId: license.id,
        productPrice: price,
        quantity: projectsCount,
        onCheckoutSessionReady: (url, error) => {
            if (!url && !error)
                return;
            if (error) {
                throw error;
            }
            if (url) {
                if (typeof window !== "undefined") {
                    window.location.assign(url);
                }
            }
        },
        type: "pro"
    });
}

export async function createPerUserLicenseSubscription(subscribe: (params: SubscribeParams) => Promise<void>, license: ProLicense, price: ProductPrice) {

    return subscribe({
        licenseId: license.id,
        productPrice: price,
        quantity: license.licensed_users,
        onCheckoutSessionReady: (url, error) => {
            if (!url && !error)
                return;
            if (error) {
                throw error;
            }
            if (url) {
                if (typeof window !== "undefined") {
                    window.location.assign(url);
                }
            }
        },
        type: "pro"
    });
}
