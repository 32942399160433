import { ArrayContainer, ArrayEntryParams } from "@firecms/core";
import { TextField } from "@firecms/ui";

export function ProjectsEditView({
                                     projectIds,
                                     setProjectIds,
                                     maxProjects
                                 }: {
    projectIds: string[];
    setProjectIds: (projectIds: string[]) => void;
    maxProjects?: number;
}) {

    const buildEntry = ({ index }: ArrayEntryParams) => {
        const projectId = projectIds[index];
        return <TextField
            type="text"
            size={"small"}
            value={projectId}
            onChange={(e) => {
                const value = e.target.value;
                const newProjectIds = [...projectIds];
                newProjectIds[index] = value;
                setProjectIds(newProjectIds);
            }}
            placeholder="Project ID"
        />;
    }

    return (
        <ArrayContainer droppableId={"project-ids"}
                        value={projectIds}
                        buildEntry={buildEntry}
                        size={"medium"}
                        includeAddButton={true}
                        newDefaultEntry={""}
                        onValueChange={(value) => setProjectIds(value)}
                        addLabel={"Add project"}
                        max={maxProjects}/>
    );
}

